import {ControlPointDto} from '@nexnox-web/core-shared';
import {PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {
  resourceAuditStore,
  ResourceAuditStoreState,
  resourceControlPointsStore,
  resourceDetailStore,
  ResourceDetailStoreState,
  resourceEntitiesStore,
  ResourceEntitiesStoreState,
  resourceEventListStore,
  ResourceEventListStoreState,
  resourceListStore,
  ResourceListStoreState,
  resourceMissionsStore,
  ResourceMissionsStoreState,
  resourceOccurredConsumptionsStore,
  ResourceOccurredConsumptionsStoreState,
  resourceOccurredErrorsListStore,
  ResourceOccurredErrorsStoreState,
  resourceOccurredHintsListStore,
  ResourceOccurredHintsStoreState,
  resourceTicketsStore,
  ResourceTicketsStoreState,
  resourceTreeViewStore,
  ResourceTreeViewStoreState,
  resourceUIEntitiesStore,
  ResourceUIEntitiesStoreState
} from './stores';

export interface ResourcesState {
  resourceTreeView: ResourceTreeViewStoreState;
  resourceDetail: ResourceDetailStoreState,
  resourceList: ResourceListStoreState,
  resourceEventList: ResourceEventListStoreState,
  resourceAudit: ResourceAuditStoreState;
  resourceTickets: ResourceTicketsStoreState;
  resourceMissions: ResourceMissionsStoreState;
  resourceControlPoints: PagedEntitiesXsStoreState<ControlPointDto>;
  resourceUIEntities: ResourceUIEntitiesStoreState;
  resourceEntities: ResourceEntitiesStoreState;
  resourceOccurredConsumptions: ResourceOccurredConsumptionsStoreState;
  resourceOccurredErrorsList: ResourceOccurredErrorsStoreState;
  resourceOccurredHintsList: ResourceOccurredHintsStoreState;
}

export const getInitialResourcesState = (): ResourcesState => ({
  resourceTreeView: resourceTreeViewStore.getInitialState(),
  resourceDetail: resourceDetailStore.getInitialState(),
  resourceList: resourceListStore.getInitialState(),
  resourceEventList: resourceEventListStore.getInitialState(),
  resourceAudit: resourceAuditStore.getInitialState(),
  resourceTickets: resourceTicketsStore.getInitialState(),
  resourceMissions: resourceMissionsStore.getInitialState(),
  resourceControlPoints: resourceControlPointsStore.getInitialState(),
  resourceUIEntities: resourceUIEntitiesStore.getInitialState(),
  resourceEntities: resourceEntitiesStore.getInitialState(),
  resourceOccurredConsumptions: resourceOccurredConsumptionsStore.getInitialState(),
  resourceOccurredErrorsList: resourceOccurredErrorsListStore.getInitialState(),
  resourceOccurredHintsList: resourceOccurredHintsListStore.getInitialState()
});
