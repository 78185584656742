<div *ngIf="item && (item.hidden ? !item.hidden(item) : true)"
     class="tree-view-item"
     [class.has-children]="item.hasChildren">
  <div #treeViewItemElement class="tree-view-item-element"
       [class.is-disabled]="disabled"
       [title]="item.name">
    <div class="tree-view-item-content" [style.padding-left]="(nestLevel * 1.25) + 'rem'">
      <div class="tree-view-item-icon" [class.opacity-0]="!item.hasChildren">
        <em *ngIf="(itemChildrenLoading$ | async) === false" class="tree-view-item-expand-icon"
            (click)="!disabled ? onClick() : null"
            [class.ion-ios-arrow-forward]="(isExpanded | async) === false"
            [class.ion-ios-arrow-down]="(isExpanded | async)">
        </em>

        <fa-icon *ngIf="itemChildrenLoading$ | async"
                 [icon]="faSpinner"
                 [spin]="true"
                 [fixedWidth]="true"
                 [classes]="['tree-view-item-loading-icon']">
        </fa-icon>
      </div>
      <div class="tree-view-item-name">
        <a [module]="item?.module ?? null" [nexnoxWebTenantRouterLink]="item?.link ?? ''"
           nexnoxWebTenantRouterLinkActive="is-selected">
          <div class="tree-view-item-text">
            <fa-icon *ngIf="item.icon" [icon]="item.icon" [size]="'sm'" class="mr-2"></fa-icon>
            <span>{{ item.name }}</span>
          </div>
        </a>
      </div>
      <div *ngIf="item.type === resourceType.Device || item.type === resourceType.Virtual"
           class="tree-view-item-info"
           [class.is-retired]="item.state === resourceState.Retired">
        <div>
          <div *ngIf="item?.location?.locationNumber">
            <div>{{ item?.location?.locationNumber }}</div>
            <div><fa-icon [icon]="faHouse" class="ml-1"></fa-icon></div>
          </div>

          <div>
            <div *ngIf="item?.inventoryNumber">{{ item.inventoryNumber }}</div>
            <div *ngIf="item?.state"
                 class="state">
              <ng-container [ngSwitch]="item.state">
                <div *ngSwitchCase="resourceState.Operation">
                  <div class="ml-1">🟢</div>
                </div>
                <div *ngSwitchCase="resourceState.Maintenance">
                  <div class="ml-1">🟡</div>
                </div>
                <div *ngSwitchCase="resourceState.Retired">
                  <div class="ml-1">🔴</div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tree-view-item-children"
       [class.expanded]="isExpanded | async">
    <ng-container *ngIf="item.hasChildren">
      <div *ngIf="(itemChildrenLoading$ | async) === false" class="tree-view-item-children-inner-container">
        <ng-container *ngFor="let child of itemChildren$ | async; trackBy: trackItemsById">
          <nexnox-web-hierarchical-tree-view-item #hierarchicalTreeViewItemComponent
                                                  [item]="child"
                                                  [nestLevel]="nestLevel + 1"
                                                  [parent]="this"
                                                  [itemSelectedEvent]="itemSelectedEvent"
                                                  [boundingElement]="boundingElement"
                                                  [canSelectItem]="canSelectItem"
                                                  (selectItem)="onChildSelectedItem($event)"
                                                  (expandItem)="onChildExpandedItem($event)"
                                                  (moreItem)="onChildMoreItem($event)">
          </nexnox-web-hierarchical-tree-view-item>
        </ng-container>

        <ng-container *ngIf="item.getPaging && item.getPaging() | async as paging">
          <ng-container *ngIf="paging.pageNumber < paging.totalPages">
            <div *ngIf="(itemChildrenNextLoading$ | async) === false; else loadingTemplate"
                 class="more-btn"
                 [style.padding-left]="getMoreButtonPaddingLeft(nestLevel)"
                 (click)="onMore()">
              <fa-icon [icon]="faPlus" class="d-flex align-items-center"></fa-icon>
              <span class="ml-2">{{ 'core-shared.shared.pagination.more' | translate }}</span>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #loadingTemplate>
      <nexnox-web-ghost-hierarchical-tree-view-children [nestLevel]="nestLevel + 1">
      </nexnox-web-ghost-hierarchical-tree-view-children>
    </ng-template>
  </div>
</div>
