import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyActionWrapperTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping,
  CorePortalPermissionService,
  CorePortalTenantRouter,
  CorePortalVirtualResourceService
} from '@nexnox-web/core-portal';
import {AppPermissions, MissionIssueDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import {techPortalFeatureMissionStateEnumOptions} from "../../../../../missions";
import {TechPortalFeatureTicketSettingsPriorityService} from "../../../../../ticket-settings/features/priority-sets";

@Component({
  selector: 'nexnox-web-issues-mission-issue-edit',
  templateUrl: './mission-issue-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionIssueEditComponent extends CorePortalEntityEditBaseComponent<MissionIssueDto> {

  constructor(
    protected injector: Injector,
    private tenantRouter: CorePortalTenantRouter,
    private permissionService: CorePortalPermissionService,
    private resourceService: CorePortalVirtualResourceService,
    private priorityService: TechPortalFeatureTicketSettingsPriorityService
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      // Mission fields
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 field-group-mb-0 mt-3 px-0',
        fieldGroupClassName: 'row',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.mission',
            title: true,
            labelClass: 'pl-2'
          } as CorePortalFormlyTranslatedTyping
        },
        fieldGroup: [
          {
            key: 'mission.title',
            type: 'input',
            wrappers: ['core-portal-translated', 'core-portal-actions', 'core-portal-readonly'],
            className: 'col-md-6',
            templateOptions: {
              type: 'text',
              corePortalTranslated: {
                label: 'core-shared.shared.fields.name',
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.BASIC
              } as CorePortalFormlyReadonlyTyping,
              corePortalActionWrapper: {
                actions: [
                  {
                    icon: faExternalLinkAlt,
                    tooltip: 'core-shared.shared.actions.view-mode',
                    onClick: () => this.tenantRouter.navigate(['missions/all', this.model?.mission?.missionId], {module: 'inventory'}).then(),
                    hidden: (model) => !Boolean(model?.mission),
                    permissions$: [this.permissionService.hasPermission$(AppPermissions.ReadMission)]
                  }
                ]
              } as CorePortalFormlyActionWrapperTyping
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            }
          },
          {
            key: 'mission.state',
            type: 'core-portal-ng-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-6',
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.mission-state',
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENUM,
                enumOptions: techPortalFeatureMissionStateEnumOptions,
                translate: true
              } as CorePortalFormlyReadonlyTyping,
              corePortalNgSelect: {
                items: techPortalFeatureMissionStateEnumOptions,
                translate: true
              }
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            },
          },
          {
            key: 'priority',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-6',
            defaultValue: null,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.priority'
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENTITY,
                displayKey: 'name'
              } as CorePortalFormlyReadonlyTyping,
              entityService: this.priorityService,
              idKey: 'priorityId',
              displayKey: 'name',
              wholeObject: true,
              multiple: false
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly,
            }
          },
          {
            key: 'resource',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-6',
            defaultValue: [],
            templateOptions: {
              corePortalTranslated: {
                label: 'core-portal.settings.fields.ticket-settings.processes.resource'
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENTITY,
                displayKey: 'name'
              } as CorePortalFormlyReadonlyTyping,
              entityService: this.resourceService,
              idKey: 'resourceId',
              displayKey: 'name',
              wholeObject: true,
              multiple: false
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            }
          },
        ]
      },
      ...this.getStereotypeFields(true)
    ];
  }
}
