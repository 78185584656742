import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {
  CorePortalContactService,
  CorePortalEntityEditBaseComponent,
  CorePortalEntitySelectOptions,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyReceiverTyping,
  CorePortalStereotypeService
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  ContactSimpleDto,
  CoreSharedSortableListItem,
  Filter,
  FilterDto,
  FilterOperators,
  FilterTypes,
  IssueContentType,
  IssueResourceSelectorKind,
  IssueTemplateDto,
  MissionEditorSelectorKind,
  PageableRequest,
  PriorityDto,
  PrioritySimpleDto,
  ResourceSimpleDto,
  StereotypeDto
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {IssueNotificationLocalDto} from "../../components";
import {BehaviorSubject, firstValueFrom, mergeMap, Observable, of, shareReplay} from "rxjs";
import {
  CorePortalTicketSettingsPriorityService,
  CorePortalTicketSettingsStateService
} from "../../../../../stereotypes/src/lib/store/services";
import {debounceTime, distinctUntilChanged, exhaustMap, filter, map, skip, take} from "rxjs/operators";
import {cloneDeep, isNumber} from "lodash";
import {TechPortalTicketService} from "@nexnox-web/tech-portal-lib";
import {
  ReceiverTabSettings
} from "../../../../../../../../src/lib/modules/formly/types/formly-receivers/receiver-type.enum";

const resourceSelectorKindEnumOptions = [
  {value: IssueResourceSelectorKind.Selection, label: 'core-shared.shared.ticket-issue-selector-types.0'},
  {value: IssueResourceSelectorKind.None, label: 'core-shared.shared.ticket-issue-selector-types.1'}
];

const missionEditorSelectorKindEnumOptions = [
  {value: MissionEditorSelectorKind.None, label: 'core-shared.shared.mission-editor-selector-types.0'},
  {value: MissionEditorSelectorKind.Editor, label: 'core-shared.shared.mission-editor-selector-types.1'},
  {value: MissionEditorSelectorKind.PrioritySearch, label: 'core-shared.shared.mission-editor-selector-types.2'},
];

@Component({
  selector: 'nexnox-web-settings-issue-template-edit',
  templateUrl: './issue-template-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureIssueTemplateEditComponent extends CorePortalEntityEditBaseComponent<IssueTemplateDto> implements OnInit {
  @Input() public issueContentType: IssueContentType;

  public selectedTicketStereotypeIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public selectedTicketStereotype$: Observable<StereotypeDto>;

  public selectedMissionPriorityItem: PriorityDto = null;
  public missionPriorityEntitySelectOptions: CorePortalEntitySelectOptions;
  public missionPriorities$: Observable<CoreSharedSortableListItem[]>;

  private missionPrioritiesSubject: BehaviorSubject<PriorityDto[]> = new BehaviorSubject<PriorityDto[]>([]);

  constructor(
    protected injector: Injector,
    private stereotypeService: CorePortalStereotypeService,
    private priorityService: CorePortalTicketSettingsPriorityService,
    private stateService: CorePortalTicketSettingsStateService,
    private ticketService: TechPortalTicketService,
    private contactService: CorePortalContactService
  ) {
    super(injector);

    // Load full Ticket StereotypeDto but cache it until user changes it
    this.selectedTicketStereotype$ = this.selectedTicketStereotypeIdSubject.asObservable().pipe(
      filter(stereotypeId => isNumber(stereotypeId)),
      distinctUntilChanged(),
      mergeMap(stereotypeId => this.stereotypeService.getOne<StereotypeDto>(stereotypeId)),
      shareReplay()
    );
  }

  public get isMissionEditorPrioritySearch(): boolean {
    return this.model?.missionContent?.editorSelectorKind === MissionEditorSelectorKind.PrioritySearch;
  }

  public get resources(): ResourceSimpleDto[] {
    return this.model?.publishedResources as ResourceSimpleDto[];
  }

  public get notifications(): IssueNotificationLocalDto[] {
    return this.model?.notifications as IssueNotificationLocalDto[];
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public onModelChange(model: IssueTemplateDto): void {
    super.onModelChange(model);
  }

  public onNotificationsChanged(notifications: any): void {
    this.onModelChange({...this.model, notifications});
  }

  public onAddMissionPriority(): void {
    if (this.selectedMissionPriorityItem) {
      const priorityList: PrioritySimpleDto[] = (cloneDeep(this.model.missionContent?.priorities) ?? []);
      priorityList.push(this.selectedMissionPriorityItem);
      this.missionPrioritiesSubject.next(priorityList);
    }
  }

  public onDeleteMissionPriority(sortableListItem: CoreSharedSortableListItem): void {
    const priorityItemData = sortableListItem.getExternalData();
    if (priorityItemData) {
      const priorityList = (cloneDeep(this.modelSubject.getValue().missionContent?.priorities) ?? [])
        .filter(p => p.priorityId !== priorityItemData.priorityId);
      this.missionPrioritiesSubject.next(priorityList);
    }
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    let additionalFormFields;
    let receiverConfig;

    switch (this.issueContentType) {
      case IssueContentType.Message:
        additionalFormFields = this._getMessageForm();
        break;
      case IssueContentType.Mission:
        this.missionPrioritiesInit();
        additionalFormFields = this._getMissionForm();
        receiverConfig = {
          useContactTab: false,
          useFunctionTab: false
        }
        break;
      case IssueContentType.Ticket:
        additionalFormFields = this._getTicketForm();
        break;
      case IssueContentType.WinterhalterServiceCall:
        additionalFormFields = this._getWinterhalterForm();
        break;
      default:
        additionalFormFields = this._getMessageForm();
        break;
    }

    return [
      ...this._getDefaultForm(receiverConfig),
      ...additionalFormFields
    ];
  }

  private _getDefaultForm(receiverConfig?: ReceiverTabSettings): FormlyFieldConfig[] {
    return [
      {key: 'issueTemplateId'},
      {key: 'contacts'},
      {key: 'functions'},
      {key: 'locations'},
      {key: 'locationGroups'},
      {
        key: 'contentType',
        defaultValue: this.issueContentType
      },
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'receivers',
        type: 'core-portal-receivers',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.receivers'
          },
          corePortalReceivers: {
            model: this.modelSubject,
            useLocationGroupTab: receiverConfig?.useLocationGroupTab ?? true,
            useLocationTab: receiverConfig?.useLocationTab ?? true,
            useContactTab: receiverConfig?.useContactTab ?? true,
            useFunctionTab: receiverConfig?.useFunctionTab ?? true
          } as CorePortalFormlyReceiverTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          titleKey: 'name',
          modelSubject: this.modelSubject
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      }
    ]
  }

  private _getMessageForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'messageContent.issueStereotype',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.issue-stereotype',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (stereotype: StereotypeDto) => stereotype?.stereotypeId ? ['stereotypes', stereotype.stereotypeId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.stereotypeService,
          idKey: 'stereotypeId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          clearable: false,
          firstToDefault: true,
          defaultFilters$: of([
            {
              property: 'isArchived',
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              value: 'false'
            },
            {
              property: 'entityType',
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              value: AppEntityType.Issue.toString()
            }
          ] as Filter[]),
          module: 'settings'
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      }
    ]
  }

  private _getMissionForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'missionContent.issueStereotype',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.issue-stereotype',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (stereotype: StereotypeDto) => stereotype?.stereotypeId ? ['stereotypes', stereotype.stereotypeId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.stereotypeService,
          idKey: 'stereotypeId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          clearable: false,
          firstToDefault: true,
          defaultFilters$: of([
            {
              property: 'isArchived',
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              value: 'false'
            },
            {
              property: 'entityType',
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              value: AppEntityType.Issue.toString()
            }
          ] as Filter[]),
          module: 'settings'
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'missionContent.stereotype',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'core-portal.settings.issue-template.fields.mission-stereotype',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (stereotype: StereotypeDto) => stereotype?.stereotypeId ? ['stereotypes', stereotype.stereotypeId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.stereotypeService,
          idKey: 'stereotypeId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          clearable: false,
          defaultFilters$: of([
            {
              property: 'isArchived',
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              value: 'false'
            },
            {
              property: 'entityType',
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              value: AppEntityType.Mission.toString()
            }
          ] as Filter[]),
          link: (stereotype: StereotypeDto) => stereotype?.stereotypeId ? ['stereotypes', stereotype.stereotypeId] : null,
          module: 'settings'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'missionContent.editorSelectorKind',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: MissionEditorSelectorKind.None,
        templateOptions: {
          required: false,
          corePortalTranslated: {
            label: 'core-portal.settings.issue-template.fields.mission-editor-selector-kind',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: missionEditorSelectorKindEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: missionEditorSelectorKindEnumOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'missionContent.fixedEditor',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.contact',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'displayName',
            link: (contact: ContactSimpleDto) => contact?.contactId ? ['masterdata', 'contacts', contact.contactId] : null,
            module: 'management'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.contactService,
          idKey: 'contactId',
          displayKey: 'displayName',
          wholeObject: true,
          skipGetOne: true,
        } as CorePortalFormlyNgSelectTyping,
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: (model) => model.missionContent?.editorSelectorKind !== MissionEditorSelectorKind.Editor
      }
    ]
  }

  private _getTicketForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'ticketContent.issueStereotype',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.issue-stereotype',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (stereotype: StereotypeDto) => stereotype?.stereotypeId ? ['stereotypes', stereotype.stereotypeId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.stereotypeService,
          idKey: 'stereotypeId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          clearable: false,
          firstToDefault: true,
          defaultFilters$: of([
            {
              property: 'isArchived',
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              value: 'false'
            },
            {
              property: 'entityType',
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              value: AppEntityType.Issue.toString()
            }
          ] as Filter[]),
          module: 'settings'
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'ticketContent.stereotype',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'core-portal.settings.issue-template.fields.ticket-stereotype',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (stereotype: StereotypeDto) => stereotype?.stereotypeId ? ['stereotypes', stereotype.stereotypeId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.stereotypeService,
          idKey: 'stereotypeId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          clearable: false,
          firstToDefault: true,
          defaultFilters$: of([
            {
              property: 'entityType',
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              value: AppEntityType.Ticket.toString()
            },
            {
              property: 'isArchived',
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              value: false.toString()
            }
          ] as Filter[]),
          module: 'settings'
        },
        hooks: {
          onInit: (field) => this.subscribe(field.formControl.valueChanges, (stereotype) => this.selectedTicketStereotypeIdSubject.next(stereotype?.stereotypeId))
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'ticketContent.state',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'tickets.fields.current-state',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.stateService,
          idKey: 'stateId',
          displayKey: 'name',
          firstToDefault: true,
          clearable: false,
          wholeObject: true,
          skipGetOne: true,
          module: 'settings',
          refresh$: this.selectedTicketStereotype$.pipe(
            map(stereotype => stereotype?.stereotypeId),
            distinctUntilChanged()
          ),
          overrideGetPage: async (pageNumber: number, pageSize: number, filters: FilterDto[]) => await firstValueFrom(this.selectedTicketStereotype$.pipe(
            filter(selectedStereotype => Boolean(selectedStereotype)),
            debounceTime(400),
            exhaustMap(selectedStereotype => this.ticketService.getPreviewStates(selectedStereotype.stateMachine?.stateMachineId).pipe(
              map(response => ({
                items: response.nextStates,
                paging: {
                  totalPages: 1,
                  pageNumber: 1,
                  pageSize: response.nextStates.length,
                  totalItems: response.nextStates.length
                }
              } as PageableRequest<any>))
            )),
            take(1)
          ))
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hooks: {
          onInit: field => {
            this.subscribe(this.selectedTicketStereotype$.pipe(
              map(stereotype => stereotype?.stereotypeId),
              filter(stereotypeId => isNumber(stereotypeId) && !this.readonly),
              distinctUntilChanged(),
              skip(1)
            ), () => field.formControl.setValue(null));
          }
        }
      },
      {
        key: 'ticketContent.priority',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.priority',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.priorityService,
          idKey: 'priorityId',
          displayKey: 'name',
          wholeObject: true,
          clearable: true,
          defaultFilters$: this.selectedTicketStereotype$.pipe(
            map(stereotype => {
              if (!stereotype || stereotype.entityType !== AppEntityType.Ticket) {
                return [];
              }

              return [{
                property: 'prioritySetId',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: stereotype.prioritySet?.prioritySetId?.toString()
              }] as FilterDto[];
            })
          ),
          firstToDefault: true,
          skipGetOne: true,
          refresh$: this.selectedTicketStereotype$.pipe(
            map(stereotype => stereotype?.stereotypeId),
            distinctUntilChanged(),
          ),
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hooks: {
          onInit: field => {
            this.subscribe(this.selectedTicketStereotype$.pipe(
              map(stereotype => stereotype?.stereotypeId),
              filter(stereotypeId => isNumber(stereotypeId) && !this.readonly),
              distinctUntilChanged(),
              skip(1)
            ), () => field.formControl.setValue(null));
          }
        }
      },
      {
        key: 'ticketContent.resourceSelectorKind',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: resourceSelectorKindEnumOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.selector-kind',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: resourceSelectorKindEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: resourceSelectorKindEnumOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      }
    ]
  }

  private _getWinterhalterForm(): FormlyFieldConfig[] {
    return [];
  }

  private missionPrioritiesInit(): void {
    this.resetMissionPriorityOptions();
    this.missionPriorities$ = this.missionPrioritiesSubject.asObservable().pipe(
      map(priorities => {
        super.onModelChange({
          ...this.model,
          missionContent: {
            ...this.model.missionContent,
            priorities
          }
        });
        this.resetMissionPriorityOptions();
        return this.mapPrioritiesToSortableItems(priorities);
      })
    );
    this.missionPrioritiesSubject.next(this.model.missionContent?.priorities ?? []);
  }

  private mapPrioritiesToSortableItems(priorities: PriorityDto[]): CoreSharedSortableListItem[] {
    return priorities.map((priority, index) => ({
        title: priority.name,
        position: index,
        getExternalData: () => priority
      })
    );
  }

  private resetMissionPriorityOptions(): void {
    this.selectedMissionPriorityItem = null;
    this.missionPriorityEntitySelectOptions = {
      idKey: 'priorityId',
      displayKey: 'name',
      entityService: this.priorityService,
      wholeObject: true,
      clearable: true,
      editable: false,
      excludedIds: cloneDeep(this.missionPrioritiesSubject.getValue() ?? []).map(p => p.priorityId)
    };
  }
}
