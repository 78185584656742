import {
  TechPortalFeatureInheritableResourceService,
  TechPortalFeatureResourceEventService,
  TechPortalFeatureResourceMissionService,
  TechPortalFeatureResourceOccurredConsumptionsService,
  TechPortalFeatureResourceOccurredErrorsService,
  TechPortalFeatureResourceOccurredHintsService,
  TechPortalFeatureResourceService,
  TechPortalFeatureResourceTicketService,
  TechPortalFeatureVirtualResourceService
} from './services';
import {
  ResourceAuditStoreEffects,
  resourceAuditStoreReducer,
  resourceControlPointsStore,
  ResourceDetailStoreEffects,
  resourceDetailStoreReducer,
  ResourceEntitiesStoreEffects,
  resourceEntitiesStoreReducer,
  ResourceEventListStoreEffects,
  resourceEventListStoreReducer,
  ResourceListStoreEffects,
  resourceListStoreReducer,
  ResourceMissionsStoreEffects,
  resourceMissionsStoreReducer,
  resourceOccurredConsumptionsStore,
  resourceOccurredErrorListStoreReducer,
  resourceOccurredErrorsListStore,
  ResourceOccurredHintListStoreEffects,
  resourceOccurredHintListStoreReducer,
  ResourceTicketsStoreEffects,
  resourceTicketsStoreReducer,
  ResourceTreeViewStoreEffects,
  resourceTreeViewStoreReducer,
  ResourceUIEntitiesStoreEffects,
  resourceUIEntitiesStoreReducer
} from './stores';

export * from './services';
export * from './stores';

export * from './resources.state';
export * from './resources.selectors';

export const resourcesReducers = {
  resourceTreeView: resourceTreeViewStoreReducer,
  resourceDetail: resourceDetailStoreReducer,
  resourceList: resourceListStoreReducer,
  resourceEventList: resourceEventListStoreReducer,
  resourceAudit: resourceAuditStoreReducer,
  resourceTickets: resourceTicketsStoreReducer,
  resourceMissions: resourceMissionsStoreReducer,
  resourceControlPoints: resourceControlPointsStore.reducer,
  resourceUIEntities: resourceUIEntitiesStoreReducer,
  resourceEntities: resourceEntitiesStoreReducer,
  resourceOccurredConsumptions: resourceOccurredConsumptionsStore.reducer,
  resourceOccurredErrorsList: resourceOccurredErrorListStoreReducer,
  resourceOccurredHintsList: resourceOccurredHintListStoreReducer
};

export const resourcesEffects = [
  ResourceTreeViewStoreEffects,
  ResourceDetailStoreEffects,
  ResourceListStoreEffects,
  ResourceEventListStoreEffects,
  ResourceAuditStoreEffects,
  ResourceTicketsStoreEffects,
  ResourceMissionsStoreEffects,
  ResourceUIEntitiesStoreEffects,
  ResourceEntitiesStoreEffects,
  resourceControlPointsStore.effects,
  resourceOccurredConsumptionsStore.effects,
  resourceOccurredErrorsListStore.effects,
  ResourceOccurredHintListStoreEffects
];

export const resourcesServices = [
  TechPortalFeatureResourceService,
  TechPortalFeatureResourceEventService,
  TechPortalFeatureResourceTicketService,
  TechPortalFeatureResourceMissionService,
  TechPortalFeatureResourceOccurredConsumptionsService,
  TechPortalFeatureResourceOccurredErrorsService,
  TechPortalFeatureResourceOccurredHintsService,
  TechPortalFeatureVirtualResourceService,
  TechPortalFeatureInheritableResourceService
];
