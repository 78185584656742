import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalVirtualResourceService
} from '@nexnox-web/core-portal';
import {WinterhalterServiceCallIssueDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {Validators} from "@angular/forms";

@Component({
  selector: 'nexnox-web-issues-winterhalter-issue-edit',
  templateUrl: './winterhalter-issue-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WinterhalterIssueEditComponent extends CorePortalEntityEditBaseComponent<WinterhalterServiceCallIssueDto> {

  constructor(
    protected injector: Injector,
    private resourceService: CorePortalVirtualResourceService
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      // Winterhalter fields
      {
        key: 'problem',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.problem',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'category',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.category',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'message',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.message',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'contactFirstName',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.first-name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'contactLastName',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.last-name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'contactEmailAddress',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'email',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.email-address',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        validators: {
          email: ctrl => !Validators.email(ctrl)
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'orderId',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.order-id',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'resource',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: [],
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.ticket-settings.processes.resource'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.resourceService,
          idKey: 'resourceId',
          displayKey: 'name',
          wholeObject: true,
          multiple: false
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      }
    ];
  }
}
