import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppEntityType, WinterhalterServiceCallIssueDto} from '@nexnox-web/core-shared';
import {winterhalterIssueDetailStore} from '../../store';

@Component({
  selector: 'nexnox-web-issues-winterhalter-issue-detail',
  templateUrl: './winterhalter-issue-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WinterhalterIssueDetailComponent extends CorePortalEntityDetailBaseComponent<WinterhalterServiceCallIssueDto> implements OnInit {

  public title = 'issues.subtitles.issue-detail';
  public entityTypes = AppEntityType;
  protected idParam = 'issueId';
  protected displayKey = 'title';

  constructor(
    protected injector: Injector
  ) {
    super(injector, winterhalterIssueDetailStore);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  protected override getReturnToListPath(): string[] {
    let goBackToList = this.router.routerState.snapshot.url.split('/');
    const issueIndex = goBackToList.findIndex(segment => segment === 'issues');
    goBackToList = goBackToList.splice(issueIndex, goBackToList.length - 1);
    return goBackToList;
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return [];
  }
}
