import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import {
  DocumentTemplateDetailComponent,
  DocumentTemplateListComponent,
  TextTemplateDetailComponent,
  TextTemplateListComponent
} from './containers';
import {
  CorePortalDynamicDefaultRouteGuard,
  CorePortalNoPermissionComponent,
  CorePortalPermissionGuard
} from '@nexnox-web/core-portal';
import {
  FormDocumentTemplateDetailWrapperComponent,
  FormDocumentTemplateListWrapperComponent,
  IssueDocumentTemplateDetailWrapperComponent,
  IssueDocumentTemplateListWrapperComponent,
  IssueTextTemplateDetailWrapperComponent,
  IssueTextTemplateListWrapperComponent,
  MissionDocumentTemplateDetailWrapperComponent,
  MissionDocumentTemplateListWrapperComponent,
  MissionTextTemplateDetailWrapperComponent,
  MissionTextTemplateListWrapperComponent,
  NotificationTextTemplateDetailWrapperComponent,
  NotificationTextTemplateListWrapperComponent,
  SolutionTextTemplateDetailWrapperComponent,
  SolutionTextTemplateListWrapperComponent,
  TicketDocumentTemplateDetailWrapperComponent,
  TicketDocumentTemplateListWrapperComponent
} from "./wrappers";

export const TECH_PORTAL_TEMPLATES_ROUTES: Routes = [
  {
    path: 'solution-text-templates',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: SolutionTextTemplateListWrapperComponent,
    data: {
      permissions: [AppPermissions.UpdateTemplate],
      breadcrumbs: I18nBreadcrumbResolver,
      text: `core-portal.core.header.breadcrumbs.settings.templates.text-templates`
    },
    children: [
      {
        path: `:templateId`,
        component: SolutionTextTemplateDetailWrapperComponent,
        canDeactivate: [UnsavedChangesGuard],
        data: {
          breadcrumbs: I18nBreadcrumbResolver,
          tab: 'template',
          text: `core-portal.core.header.breadcrumbs.settings.templates.text-template`
        }
      },
    ]
  },

  {
    path: 'issue-text-templates',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: IssueTextTemplateListWrapperComponent,
    data: {
      permissions: [AppPermissions.UpdateTemplate],
      breadcrumbs: I18nBreadcrumbResolver,
      text: `core-portal.core.header.breadcrumbs.settings.templates.text-templates`
    },
    children: [
      {
        path: `:templateId`,
        component: IssueTextTemplateDetailWrapperComponent,
        canDeactivate: [UnsavedChangesGuard],
        data: {
          breadcrumbs: I18nBreadcrumbResolver,
          tab: 'template',
          text: `core-portal.core.header.breadcrumbs.settings.templates.text-template`
        }
      },
    ]
  },

  {
    path: 'notification-text-templates',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: NotificationTextTemplateListWrapperComponent,
    data: {
      permissions: [AppPermissions.UpdateTemplate],
      breadcrumbs: I18nBreadcrumbResolver,
      text: `core-portal.core.header.breadcrumbs.settings.templates.text-templates`
    },
    children: [
      {
        path: `:templateId`,
        component: NotificationTextTemplateDetailWrapperComponent,
        canDeactivate: [UnsavedChangesGuard],
        data: {
          breadcrumbs: I18nBreadcrumbResolver,
          tab: 'template',
          text: `core-portal.core.header.breadcrumbs.settings.templates.text-template`
        }
      }
    ]
  },

  {
    path: 'mission-text-templates',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: MissionTextTemplateListWrapperComponent,
    data: {
      permissions: [AppPermissions.UpdateTemplate],
      breadcrumbs: I18nBreadcrumbResolver,
      text: `core-portal.core.header.breadcrumbs.settings.templates.text-templates`
    },
    children: [
      {
        path: `:templateId`,
        component: MissionTextTemplateDetailWrapperComponent,
        canDeactivate: [UnsavedChangesGuard],
        data: {
          breadcrumbs: I18nBreadcrumbResolver,
          tab: 'template',
          text: `core-portal.core.header.breadcrumbs.settings.templates.text-template`
        }
      }
    ]
  },

  {
    path: 'ticket-document-templates',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: TicketDocumentTemplateListWrapperComponent,
    data: {
      permissions: [AppPermissions.UpdateDocumentTemplate],
      breadcrumbs: I18nBreadcrumbResolver,
      text: `core-portal.core.header.breadcrumbs.settings.templates.document-templates`
    },
    children: [
      {
        path: `:documentTemplateId`,
        component: TicketDocumentTemplateDetailWrapperComponent,
        canDeactivate: [UnsavedChangesGuard],
        data: {
          breadcrumbs: I18nBreadcrumbResolver,
          tab: 'template',
          text: `core-portal.core.header.breadcrumbs.settings.templates.document-template`
        }
      },
    ]
  },

  {
    path: 'issue-document-templates',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: IssueDocumentTemplateListWrapperComponent,
    data: {
      permissions: [AppPermissions.UpdateDocumentTemplate],
      breadcrumbs: I18nBreadcrumbResolver,
      text: `core-portal.core.header.breadcrumbs.settings.templates.document-templates`
    },
    children: [
      {
        path: `:documentTemplateId`,
        component: IssueDocumentTemplateDetailWrapperComponent,
        canDeactivate: [UnsavedChangesGuard],
        data: {
          breadcrumbs: I18nBreadcrumbResolver,
          tab: 'template',
          text: `core-portal.core.header.breadcrumbs.settings.templates.document-template`
        }
      }
    ]
  },

  {
    path: 'mission-document-templates',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: MissionDocumentTemplateListWrapperComponent,
    data: {
      permissions: [AppPermissions.UpdateDocumentTemplate],
      breadcrumbs: I18nBreadcrumbResolver,
      text: `core-portal.core.header.breadcrumbs.settings.templates.document-templates`
    },
    children: [
      {
        path: `:documentTemplateId`,
        component: MissionDocumentTemplateDetailWrapperComponent,
        canDeactivate: [UnsavedChangesGuard],
        data: {
          breadcrumbs: I18nBreadcrumbResolver,
          tab: 'template',
          text: `core-portal.core.header.breadcrumbs.settings.templates.document-template`
        }
      }
    ]
  },

  {
    path: 'form-document-templates',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: FormDocumentTemplateListWrapperComponent,
    data: {
      permissions: [AppPermissions.UpdateDocumentTemplate],
      breadcrumbs: I18nBreadcrumbResolver,
      text: `core-portal.core.header.breadcrumbs.settings.templates.document-templates`
    },
    children: [
      {
        path: `:documentTemplateId`,
        component: FormDocumentTemplateDetailWrapperComponent,
        canDeactivate: [UnsavedChangesGuard],
        data: {
          breadcrumbs: I18nBreadcrumbResolver,
          tab: 'template',
          text: `core-portal.core.header.breadcrumbs.settings.templates.document-template`
        }
      }
    ]
  },


  // Text templates (old routes)
  {
    path: 'text',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: TextTemplateListComponent,
    data: {
      permissions: [AppPermissions.UpdateTemplate],
      breadcrumbs: I18nBreadcrumbResolver,
      text: `core-portal.core.header.breadcrumbs.settings.templates.text-templates`
    },
    children: [
      {
        path: `:templateId`,
        component: TextTemplateDetailComponent,
        canDeactivate: [UnsavedChangesGuard],
        data: {
          breadcrumbs: I18nBreadcrumbResolver,
          tab: 'template',
          text: `core-portal.core.header.breadcrumbs.settings.templates.text-template`
        }
      }
    ]
  },

  // Document templates (old routes)
  {
    path: 'document',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: DocumentTemplateListComponent,
    data: {
      permissions: [AppPermissions.UpdateDocumentTemplate],
      breadcrumbs: I18nBreadcrumbResolver,
      text: `core-portal.core.header.breadcrumbs.settings.templates.document-templates`
    },
    children: [
      {
        path: `:documentTemplateId`,
        component: DocumentTemplateDetailComponent,
        canDeactivate: [UnsavedChangesGuard],
        data: {
          breadcrumbs: I18nBreadcrumbResolver,
          tab: 'template',
          text: `core-portal.core.header.breadcrumbs.settings.templates.document-template`
        }
      }
    ]
  },

  {path: 'no-permission', component: CorePortalNoPermissionComponent},
  {
    path: '**',
    canActivate: [CorePortalDynamicDefaultRouteGuard],
    children: [],
    data: {
      defaultRoutes: [
        {path: 'text', children: [], permissions: [AppPermissions.UpdateTemplate]},
        {path: 'document', children: [], permissions: [AppPermissions.UpdateDocumentTemplate]},
        {path: 'no-permission', children: [], permissions: []}
      ]
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(TECH_PORTAL_TEMPLATES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class TemplatesRoutingModule {
}
