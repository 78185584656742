import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {faCheck, faPlus} from "@fortawesome/free-solid-svg-icons";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {
  CorePortalContactService,
  CorePortalDocumentTemplateService,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFunctionService,
  CorePortalTextTemplateService
} from "@nexnox-web/core-portal";
import {
  BasicTableColumnType,
  BasicTableConfig,
  CombineOperator,
  ContactIssueReceiverDto,
  DocumentTemplateContextType,
  DocumentTemplateSimpleDto,
  EmailIssueReceiverDto,
  Filter,
  FilterDto,
  FilterKind,
  FilterOperators,
  FilterTypes,
  FunctionIssueReceiverDto,
  GroupedFilterDto,
  IssueContentType,
  IssueNotificationType,
  IssueReceiverType,
  TemplateContextType,
  TemplateSimpleDto,
  UnsubscribeHelper
} from "@nexnox-web/core-shared";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {BindObservable} from "bind-observable";
import {cloneDeep, isEqual} from "lodash";
import {BehaviorSubject, distinctUntilChanged, filter, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {issueNotificationReceiverTypeEnumOptions, issueNotificationTypeEnumOptions} from "../../models";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";


export interface IssueNotificationLocalDto {
  // It will contain more IssueContentType properties (Ticket and Mission) in the future
  type: IssueNotificationType;
  receiver: ContactIssueReceiverDto | EmailIssueReceiverDto | FunctionIssueReceiverDto;
  emailSubjectTemplate: TemplateSimpleDto;
  emailContentTemplate: TemplateSimpleDto;
  attachments: DocumentTemplateSimpleDto[];
}

interface NotificationTableDto {
  type: IssueNotificationType;
  title: string;
  receiver: IssueReceiverType;
}

@Component({
  selector: 'nexnox-web-settings-notification-issue',
  templateUrl: 'notification-issue.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationIssueComponent extends UnsubscribeHelper implements OnInit {

  @Input()
  public set notifications(notifications: IssueNotificationLocalDto[]) {
    this.notificationsSubject.next(notifications ?? []);
  }

  public get notifications(): IssueNotificationLocalDto[] {
    return this.notificationsSubject.getValue() ?? [];
  }

  @Input() public loading: boolean;

  @Input()
  public set issueContentType(type: IssueContentType) {
    this.issueContentTypeSubject.next(type);
  }

  public get issueContentType(): IssueContentType {
    return this.issueContentTypeSubject.getValue();
  }

  @Input() @BindObservable() public readonly: boolean;
  public readonly$!: Observable<boolean>;
  public readonlySubject!: BehaviorSubject<boolean>;

  // NOTE: this updates the notificationsSubject automatically
  @Output() public notificationsChanged = new EventEmitter<IssueNotificationLocalDto[]>();

  public notificationsSubject: BehaviorSubject<IssueNotificationLocalDto[]> = new BehaviorSubject<IssueNotificationLocalDto[]>([]);
  public issueContentTypeSubject: BehaviorSubject<IssueContentType> = new BehaviorSubject<IssueContentType>(null);

  public tableItems$: Observable<NotificationTableDto[]>;

  public pdfAttachmentContextTypeFilter$: Observable<GroupedFilterDto[]>;
  public subjectContextTypeFilter$: Observable<Filter[]>;
  public contentContextTypeFilter$: Observable<Filter[]>;

  public notificationForm: FormGroup;
  public notificationFields: FormlyFieldConfig[];
  public notificationModelSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notificationTableConfig: BasicTableConfig;

  public isEditingNotification: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isEditingNotification$: Observable<boolean>;

  public faPlus = faPlus;
  public faCheck = faCheck;
  public faTrash = faTrash;

  private _editIndex: number;

  constructor(
    private contactService: CorePortalContactService,
    private functionsService: CorePortalFunctionService,
    private textTemplateService: CorePortalTextTemplateService,
    private documentTemplateService: CorePortalDocumentTemplateService
  ) {
    super();

    this.isEditingNotification$ = this.isEditingNotification.asObservable();
  }

  public ngOnInit(): void {
    this.tableItems$ = this.notificationsSubject.asObservable().pipe(
      map((notifications: IssueNotificationLocalDto[]) => this._mapNotificationsToTableItems(notifications))
    );

    this.contentContextTypeFilter$ = this.issueContentTypeSubject.pipe(map(type => this._getContentContextTypeFilter(type)));
    this.subjectContextTypeFilter$ = this.issueContentTypeSubject.pipe(map(type => this._getSubjectContextTypeFilter(type)));
    this.pdfAttachmentContextTypeFilter$ = this.issueContentTypeSubject.pipe(map(type => this._getPDFAttachmentContextTypeFilters(type)));

    this.notificationForm = new FormGroup({});
    this.notificationFields = this.createFields();

    this.notificationTableConfig = {
      columns: [
        {
          key: 'type',
          header: 'core-portal.settings.issue-template.notification-type',
          type: BasicTableColumnType.Enum,
          enumOptions: issueNotificationTypeEnumOptions
        },
        {
          key: 'receiver',
          header: 'core-portal.settings.issue-template.receiver-type',
          type: BasicTableColumnType.Enum,
          enumOptions: issueNotificationReceiverTypeEnumOptions
        },
        {
          key: 'title',
          header: 'core-portal.settings.issue-template.receiver',
          type: BasicTableColumnType.Text
        },
      ]
    }

    this.subscribe(this.readonly$, () => {
      this._updateTableActionsVisibility();
    })
  }

  public handleNotificationAction(): void {
    this.isEditingNotification.getValue() ? this.onEditNotification() : this.onAddNotification();
  }

  public onAddNotification(): void {
    const notifications: IssueNotificationLocalDto[] = cloneDeep(this.notifications);
    notifications.push(this._mapModelToLocalNotification());
    this.notificationsChanged.emit(notifications);
    this._resetForm();
  }

  public onDeleteNotification(index: number): void {
    const notifications: IssueNotificationLocalDto[] = cloneDeep(this.notifications ?? []);
    notifications.splice(index, 1);
    this.notificationsChanged.emit(notifications);
  }

  public onEditNotification(): void {
    const notifications: IssueNotificationLocalDto[] = cloneDeep(this.notifications);
    notifications[this._editIndex] = this._mapModelToLocalNotification();
    this.notificationsChanged.emit(notifications);
    this._resetForm();
    this.isEditingNotification.next(false);
  }

  public onCancelEdit(): void {
    this._resetForm();
    this.isEditingNotification.next(false);
  }

  private _resetForm(): void {
    this.notificationForm.reset();
    this.notificationForm.markAsUntouched();
    this.notificationModelSubject.next({} as IssueNotificationLocalDto);
  }

  private _toggleEditNotification(index: number): void {
    this._setNotificationIntoForm(this.notifications[index])
    this._editIndex = index;
    this.isEditingNotification.next(true);
  }

  private _getPDFAttachmentContextTypeFilters(type: IssueContentType): GroupedFilterDto[] {
    let pdfContext: DocumentTemplateContextType;

    switch (type) {
      case IssueContentType.Message:
        pdfContext = DocumentTemplateContextType.MessageIssue;
        break;
      case IssueContentType.Mission:
        pdfContext = DocumentTemplateContextType.MissionIssue;
        break;
      case IssueContentType.Ticket:
        pdfContext = DocumentTemplateContextType.TicketIssue;
        break;
      case IssueContentType.WinterhalterServiceCall:
        pdfContext = DocumentTemplateContextType.WinterhalterIssue;
        break;
      default:
        pdfContext = DocumentTemplateContextType.Issue;
    }

    return [
      {
        type: FilterTypes.Grouped,
        combinedAs: CombineOperator.Or,
        kind: FilterKind.Grouped,
        children: [
          {
            property: 'contextType',
            operator: FilterOperators.Equal,
            type: FilterTypes.DataTransferObject,
            value: DocumentTemplateContextType.Issue.toString()
          },
          {
            property: 'contextType',
            operator: FilterOperators.Equal,
            type: FilterTypes.DataTransferObject,
            value: pdfContext?.toString()
          }
        ] as FilterDto
      } as GroupedFilterDto
    ];
  }

  private _getContentContextTypeFilter(type: IssueContentType): GroupedFilterDto[] {

    let contentContext: TemplateContextType;

    switch (type) {
      case IssueContentType.Message:
        contentContext = TemplateContextType.MessageIssueEmailContent;
        break;
      case IssueContentType.Mission:
        contentContext = TemplateContextType.MissionIssueEmailContent;
        break;
      case IssueContentType.Ticket:
        contentContext = TemplateContextType.TicketIssueEmailContent;
        break;
      case IssueContentType.WinterhalterServiceCall:
        contentContext = TemplateContextType.WinterhalterIssueEmailContent;
        break;
      default:
        contentContext = TemplateContextType.IssueEmailContent;
    }

    return [
      {
        type: FilterTypes.Grouped,
        combinedAs: CombineOperator.Or,
        kind: FilterKind.Grouped,
        children: [
          {
            property: 'context',
            operator: FilterOperators.Equal,
            type: FilterTypes.DataTransferObject,
            value: TemplateContextType.IssueEmailContent.toString()
          },
          {
            property: 'context',
            operator: FilterOperators.Equal,
            type: FilterTypes.DataTransferObject,
            value: contentContext?.toString()
          }
        ] as FilterDto
      } as GroupedFilterDto
    ];
  }

  private _getSubjectContextTypeFilter(type: IssueContentType): GroupedFilterDto[] {

    let subjectContext: TemplateContextType;

    switch (type) {
      case IssueContentType.Message:
        subjectContext = TemplateContextType.MessageIssueEmailSubject;
        break;
      case IssueContentType.Mission:
        subjectContext = TemplateContextType.MissionIssueEmailSubject;
        break;
      case IssueContentType.Ticket:
        subjectContext = TemplateContextType.TicketIssueEmailSubject;
        break;
      case IssueContentType.WinterhalterServiceCall:
        subjectContext = TemplateContextType.WinterhalterIssueEmailSubject;
        break;
      default:
        subjectContext = TemplateContextType.IssueEmailSubject;
    }

    return [
      {
        type: FilterTypes.Grouped,
        combinedAs: CombineOperator.Or,
        kind: FilterKind.Grouped,
        children: [
          {
            property: 'context',
            operator: FilterOperators.Equal,
            type: FilterTypes.DataTransferObject,
            value: TemplateContextType.IssueEmailSubject.toString()
          },
          {
            property: 'context',
            operator: FilterOperators.Equal,
            type: FilterTypes.DataTransferObject,
            value: subjectContext?.toString()
          }
        ] as FilterDto
      } as GroupedFilterDto
    ];
  }

  private _mapNotificationsToTableItems(notifications: IssueNotificationLocalDto[]): NotificationTableDto[] {
    return notifications.map((notification: IssueNotificationLocalDto) => {
      let title = '';

      switch (notification.receiver.type) {
        case IssueReceiverType.ContactReceiver:
          title = (notification.receiver as ContactIssueReceiverDto).contact?.displayName;
          break;
        case IssueReceiverType.EmailReceiver:
          title = (notification.receiver as EmailIssueReceiverDto).email;
          break;
        case IssueReceiverType.FunctionReceiver:
          title = (notification.receiver as FunctionIssueReceiverDto | any).function?.name;
          break;
      }
      return {
        type: notification.type,
        receiver: notification.receiver.type as IssueReceiverType,
        title
      } as NotificationTableDto;
    });
  }

  private _mapModelToLocalNotification(): IssueNotificationLocalDto {
    const model = cloneDeep(this.notificationModelSubject.getValue());
    let receiver = {};

    switch (this.notificationModelSubject.getValue().receiver) {
      case IssueReceiverType.ContactReceiver:
        (receiver as ContactIssueReceiverDto) = {
          contact: model.contact,
          type: IssueReceiverType.ContactReceiver
        }
        break;
      case IssueReceiverType.EmailReceiver:
        (receiver as EmailIssueReceiverDto) = {
          email: model.email,
          type: IssueReceiverType.EmailReceiver
        }
        break;
      case IssueReceiverType.FunctionReceiver:
        (receiver as FunctionIssueReceiverDto | any) = {
          function: model.function,
          type: IssueReceiverType.FunctionReceiver
        }
        break;
    }

    return {
      type: model.type.value,
      emailSubjectTemplate: model.emailSubjectTemplate,
      emailContentTemplate: model.emailContentTemplate,
      attachments: model.attachments,
      receiver
    } as IssueNotificationLocalDto;

  }

  private _setNotificationIntoForm(notification: IssueNotificationLocalDto): void {
    const model: any = {
      emailSubjectTemplate: notification.emailSubjectTemplate,
      emailContentTemplate: notification.emailContentTemplate,
      attachments: notification.attachments,
      receiver: notification.receiver.type
    };

    switch (model.receiver) {
      case IssueReceiverType.ContactReceiver:
        model.type = issueNotificationReceiverTypeEnumOptions[0];
        model.contact = (notification.receiver as ContactIssueReceiverDto).contact;
        break;
      case IssueReceiverType.EmailReceiver:
        model.type = issueNotificationReceiverTypeEnumOptions[0];
        model.email = (notification.receiver as EmailIssueReceiverDto).email;
        break;
      case IssueReceiverType.FunctionReceiver:
        model.type = issueNotificationReceiverTypeEnumOptions[0];
        model.function = (notification.receiver as FunctionIssueReceiverDto | any).function;
        break;
    }

    this.notificationModelSubject.next(model);
  }

  private _updateTableActionsVisibility(): void {
    this.notificationTableConfig.actions =
      (this.readonly || this.isEditingNotification.getValue()) ? undefined : [
        {
          icon: faPencilAlt,
          tooltip: 'core-portal.core.general.edit',
          disabled: () => this.readonly || this.isEditingNotification.getValue(),
          click: (row, index) => this._toggleEditNotification(index)
        },
        {
          icon: faTrash,
          tooltip: 'core-portal.settings.issue-template.remove-notification',
          disabled: () => this.readonly || this.isEditingNotification.getValue(),
          click: (row, index) => this.onDeleteNotification(index)
        }
      ];
  }

  private createFields(): any {

    /* istanbul ignore next */
    return [
      {key: 'issueNotificationId'},
      {
        key: 'type',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3',
        defaultValue: issueNotificationTypeEnumOptions[0],
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.issue-template.notification-type',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: issueNotificationTypeEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: issueNotificationTypeEnumOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'receiver',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3',
        defaultValue: IssueReceiverType.EmailReceiver,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.issue-template.receiver',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: issueNotificationReceiverTypeEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: issueNotificationReceiverTypeEnumOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'email',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'email',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.email-address',
            validationMessages: {
              email: 'core-portal.core.validation.email',
              required: 'core-portal.core.validation.required'
            }
          },
          required: true,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => this.notificationForm.get('receiver').value !== IssueReceiverType.EmailReceiver,
        validators: {
          email: ctrl => !Validators.email(ctrl)
        }
      },
      {
        key: 'contact',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          showError: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.contact',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              email: 'core-portal.core.validation.email'
            }
          },
          entityService: this.contactService,
          idKey: 'contactId',
          displayKey: 'displayName',
          wholeObject: true,
          addTagFn: (term: string) => ({emailAddress: term, displayName: term}),
          required: true
        },
        hideExpression: () => this.notificationForm.get('receiver').value !== IssueReceiverType.ContactReceiver,
        validators: {
          email: ctrl => ctrl.value ?
            Boolean(ctrl.value.emailAddress) && !Validators.email(new FormControl(ctrl.value.emailAddress)) : true
        }
      },
      {
        key: 'function',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.function',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              duplicate: 'core-portal.core.validation.duplicate'
            }
          },
          entityService: this.functionsService,
          idKey: 'functionId',
          displayKey: 'name',
          skipGetOne: true,
          wholeObject: true,
          clearable: false
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => this.notificationForm.get('receiver').value !== IssueReceiverType.FunctionReceiver
      },
      {
        key: 'emailSubjectTemplate',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.text-template-for-mail-subject',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.textTemplateService,
          idKey: 'templateId',
          displayKey: 'title',
          clearable: false,
          wholeObject: true,
          refresh$: this.subjectContextTypeFilter$.pipe(filter(filter => !!filter)),
          defaultFilters$: this.subjectContextTypeFilter$
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,// || this._isTemplateFieldDisabled(),
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'emailContentTemplate',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.text-template-for-mail-content',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.textTemplateService,
          idKey: 'templateId',
          displayKey: 'title',
          clearable: false,
          wholeObject: true,
          refresh$: this.contentContextTypeFilter$.pipe(filter(filter => !!filter)),
          defaultFilters$: this.contentContextTypeFilter$
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,// || this._isTemplateFieldDisabled(),
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'attachments',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.pdf-attachment',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'title',
            link: (row: DocumentTemplateSimpleDto) => row?.documentTemplateId ? ['templates/document', row.documentTemplateId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.documentTemplateService,
          idKey: 'documentTemplateId',
          displayKey: 'title',
          wholeObject: true,
          refresh$: this.pdfAttachmentContextTypeFilter$.pipe(distinctUntilChanged((pre, cur) => isEqual(pre, cur))),
          defaultFilters$: this.pdfAttachmentContextTypeFilter$,
          multiple: true,
          showAll: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      }
    ]
  }

}
