import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ResourceDetailComponent, ResourcesComponent, TechPortalFeatureResourceListComponent} from './containers';
import {AppPermissions, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import {ResourceBreadcrumbsResolver} from './resolvers';
import {CorePortalPermissionGuard} from "@nexnox-web/core-portal";
import {resourceDetailStore} from "./store";

const CORE_PORTAL_RESOURCES_ROUTES: Routes = [
  {
    path: '',
    component: ResourcesComponent,
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumbs: ResourceBreadcrumbsResolver,
      permissions: [AppPermissions.DisplayResources]
    },
    children: [
      {
        path: '',
        component: TechPortalFeatureResourceListComponent,
        canActivate: [CorePortalPermissionGuard],
        canDeactivate: [UnsavedCreateGuard],
        data: {
          permissions: [AppPermissions.DisplayResources]
        }
      },
      {
        path: `:resourceId`,
        component: ResourceDetailComponent,
        canActivate: [CorePortalPermissionGuard],
        canDeactivate: [UnsavedChangesGuard],
        data: {
          entityStore: resourceDetailStore,
          idProperty: 'resourceId',
          displayProperty: 'name',
          tab: 'resource',
          text: 'resources.subtitles.resource-detail',
          permissions: [AppPermissions.ReadResource]
        },
        children: [
          {
            path: 'children',
            data: {
              tab: 'children',
              text: 'resources.subtitles.resource-children'
            },
            children: []
          },
          {
            path: 'editor-suggestions',
            data: {
              tab: 'editor-suggestions',
              text: 'resources.subtitles.editor-suggestions'
            },
            children: []
          },
          {
            path: 'escalation-rules',
            data: {
              tab: 'escalation-rules',
              text: 'resources.subtitles.escalation-rules'
            },
            children: []
          },
          {
            path: 'links',
            data: {
              tab: 'links',
              text: 'tech-portal.subtitles.links'
            },
            children: []
          },
          {
            path: 'attachments',
            data: {
              tab: 'attachments',
              text: 'tech-portal.subtitles.attachments'
            },
            children: []
          },
          {
            path: 'tickets',
            data: {
              tab: 'tickets',
              text: 'tickets.title'
            },
            children: []
          },
          {
            path: 'missions',
            data: {
              tab: 'missions',
              text: 'missions.title'
            },
            children: []
          },
          {
            path: 'control-points',
            data: {
              tab: 'control-points',
              text: 'tech-portal.ccp.control-points.subtitles.list'
            },
            children: []
          },
          {
            path: 'connected',
            data: {
              tab: 'connected',
              text: 'tech-portal.connected.title'
            },
            children: []
          },
          {
            path: 'audit',
            data: {
              tab: 'audit',
              text: 'core-portal.audit.title'
            },
            children: []
          },
          {
            path: 'knowledge-articles',
            data: {
              tab: 'knowledge-articles',
              text: 'orga-portal.knowledge.subtitles.article-list'
            },
            children: []
          },
          {
            path: 'issue-templates',
            data: {
              tab: 'issue-templates',
              text: 'core-portal.settings.issue-template.titles.list'
            },
            children: []
          },
          {
            path: 'quick-access',
            data: {
              tab: 'quick-access',
              text: 'resources.fields.inherit-quick-access'
            },
            children: []
          },
        ]
      }
    ]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CORE_PORTAL_RESOURCES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class ResourcesRoutingModule {
}
