import {ChangeDetectionStrategy, Component, Injector, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import {
  authStore,
  CorePortalAttachmentsComponent,
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalPermissionService,
  ModelValid
} from '@nexnox-web/core-portal';
import {
  CorePortalFeatureMasterDataLocationService,
  noClosedLocationsFilter$
} from '@nexnox-web/core-portal/features/master-data/features/locations';
import {
  CorePortalFeatureResourceInventoryNumberComponent,
  CorePortalFeatureResourcePathComponent,
  CorePortalFeatureResourceService
} from '@nexnox-web/core-portal/features/resources';
import {
  AlternativeEditorSuggestionDto,
  AppEntityType,
  AppPermissions,
  AttachmentForTechDto,
  EscalationRuleDto,
  FilterDto,
  IssueTemplateSimpleDto,
  KnowledgeArticleSimpleDto,
  LinkDto,
  LinkedElementType,
  LocationDto,
  LocationGroupDto,
  NexnoxWebFaIconString,
  PrimaryEditorSuggestionDto,
  ResourceDto,
  ResourceManufacturerDto,
  ResourceModelDto,
  ResourceType,
  StereotypeListDto
} from '@nexnox-web/core-shared';
import {TechPortalLinksComponent} from '@nexnox-web/tech-portal-lib';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {isEqual} from 'lodash';
import {ConfirmationService} from 'primeng/api';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map, skip, startWith, take} from 'rxjs/operators';
import {TechPortalFeatureInheritableResourceService} from '../../store/services';
import {EscalationRulesComponent} from '../escalation-rules/escalation-rules.component';
import {
  EditorSuggestionsChangedEvent,
  EditorSuggestionsComponent
} from "../editor-suggestions/editor-suggestions.component";
import {
  QuickAccessOptionsPayload,
  ResourceQuickAccessComponent,
} from "../resource-quick-access/resource-quick-access.component";
import {
  CorePortalFeatureMasterDataLocationGroupService
} from "@nexnox-web/core-portal/features/master-data/features/location-groups";
import {
  resourceTypeEnumOptions,
  resourceTypeSelectableCreateEnumOptions,
  resourceTypeSelectableEditEnumOptions
} from "../../models";
import {
  CorePortalManufacturerModelService,
  CorePortalManufacturerService
} from "@nexnox-web/core-portal/features/settings/features/manufacturers/src/lib/store/services";
import {select, Store} from "@ngrx/store";

@Component({
  selector: 'nexnox-web-resources-resource-edit',
  templateUrl: './resource-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureFeatureResourceEditComponent extends CorePortalEntityEditBaseComponent<ResourceDto> implements OnInit, ModelValid {
  @ViewChild('locationSelectLabelTitleTemplate', {static: true}) public locationSelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('locationSelectOptionTitleTemplate', {static: true}) public locationSelectOptionTitleTemplate: TemplateRef<any>;

  @ViewChild('resourceSelectLabelTitleTemplate', {static: true}) public resourceSelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('resourceSelectOptionTitleTemplate', {static: true}) public resourceSelectOptionTitleTemplate: TemplateRef<any>;
  @ViewChild('resourceSelectOptionTemplate', {static: true}) public resourceSelectOptionTemplate: TemplateRef<any>;
  @ViewChild('locationGroupDescriptionTemplate', {static: true}) public locationGroupDescriptionTemplate: TemplateRef<any>;

  @ViewChild('editorSuggestionsComponent') public editorSuggestionsComponent: EditorSuggestionsComponent;
  @ViewChild('escalationRulesComponent') public escalationRulesComponent: EscalationRulesComponent;
  @ViewChild('linksComponent') public linksComponent: TechPortalLinksComponent;
  @ViewChild('attachmentsComponent') public attachmentsComponent: CorePortalAttachmentsComponent;
  @ViewChild('quickAccessComponent') public quickAccessComponent: ResourceQuickAccessComponent;

  public currentTenantId: number;

  public primaryEditorSuggestions$: Observable<PrimaryEditorSuggestionDto[]>;
  public alternativeEditorSuggestions$: Observable<AlternativeEditorSuggestionDto[]>;
  public escalationRules$: Observable<EscalationRuleDto[]>;
  public links$: Observable<LinkDto[]>;
  public attachments$: Observable<AttachmentForTechDto[]>;
  public resourceTypeSubject: BehaviorSubject<ResourceType> = new BehaviorSubject<ResourceType>(ResourceType.Device);

  public readAuditPermission$: Observable<boolean>;
  public displayQuickAccessConfigPermission$: Observable<boolean>;
  public displayConnectedDefinitionPermission$: Observable<boolean>;
  public createControlPointPermission$: Observable<boolean>;

  public entityTypes = AppEntityType;
  public linkedElementTypes = LinkedElementType;

  public titleFieldFn: any;
  public customTitle$: Observable<string>;

  public resourceSubject: BehaviorSubject<ResourceDto> = new BehaviorSubject<ResourceDto>(null);
  public articlesSubject: BehaviorSubject<KnowledgeArticleSimpleDto[]> = new BehaviorSubject<KnowledgeArticleSimpleDto[]>([])
  public issueTemplatesSubject: BehaviorSubject<IssueTemplateSimpleDto[]> = new BehaviorSubject<IssueTemplateSimpleDto[]>([])
  public refreshManufacturerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public refreshManufacturerModelSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private primaryEditorSuggestionsSubject: BehaviorSubject<PrimaryEditorSuggestionDto[]> = new BehaviorSubject<PrimaryEditorSuggestionDto[]>([]);
  private alternativeEditorSuggestionsSubject: BehaviorSubject<AlternativeEditorSuggestionDto[]> = new BehaviorSubject<AlternativeEditorSuggestionDto[]>([]);
  private escalationRulesSubject: BehaviorSubject<EscalationRuleDto[]> = new BehaviorSubject<EscalationRuleDto[]>([]);
  private linksSubject: BehaviorSubject<LinkDto[]> = new BehaviorSubject<LinkDto[]>([]);
  private attachmentsSubject: BehaviorSubject<AttachmentForTechDto[]> = new BehaviorSubject<AttachmentForTechDto[]>([]);

  private inventoryNumberCountField: FormlyFieldConfig;

  constructor(
    protected injector: Injector,
    private store: Store,
    private permissionService: CorePortalPermissionService,
    private resourceService: TechPortalFeatureInheritableResourceService,
    private locationService: CorePortalFeatureMasterDataLocationService,
    private locationGroupService: CorePortalFeatureMasterDataLocationGroupService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private manufacturerService: CorePortalManufacturerService,
    private manufacturerModelService: CorePortalManufacturerModelService
  ) {
    super(injector, 'TechPortalFeatureFeatureResourceEditComponent');

    this.titleFieldFn = base => this.getTitleFields(base);

    this.readAuditPermission$ = this.permissionService.hasPermission$(AppPermissions.ReadAudit);
    this.displayConnectedDefinitionPermission$ = this.permissionService.hasPermission$(AppPermissions.DisplayConnectedDefinition);
    this.createControlPointPermission$ = this.permissionService.hasPermission$(AppPermissions.CreateControlPoint);
    this.displayQuickAccessConfigPermission$ = this.permissionService.hasPermission$(AppPermissions.DisplayQuickAccessConfiguration);
  }

  public ngOnInit(): void {
    super.ngOnInitSimple();

    this.inventoryNumberCountField = this.getInventoryNumberCountField();

    this.ngOnInitForm();

    if (this.creating) this.model.inheritsKnowledge = true;

    this.subscribe(this.resourceSubject, (model) => {
      this.readonly = model?.type === ResourceType.Virtual ? true : this.readonly;
    });

    this.primaryEditorSuggestions$ = this.primaryEditorSuggestionsSubject.asObservable();
    this.alternativeEditorSuggestions$ = this.alternativeEditorSuggestionsSubject.asObservable();
    this.escalationRules$ = this.escalationRulesSubject.asObservable();
    this.links$ = this.linksSubject.asObservable();
    this.attachments$ = this.attachmentsSubject.asObservable();

    this.customTitle$ = this.modelSubject.asObservable().pipe(
      filter(model => Boolean(model)),
      map(model => CorePortalFeatureResourceInventoryNumberComponent.prepareTitle(
        model,
        CorePortalFeatureResourceInventoryNumberComponent.prepareTitlePrefix(model, '')
      ))
    );

    this.ngOnInitSubscriptions();

    this.subscribe(this.store.pipe((select(authStore.selectors.selectTenantId))), (tenantId) => this.currentTenantId = tenantId);
    this.subscribe(this.refreshManufacturerModelSubject)
  }

  public onEscalationRulesChange(escalationRules: EscalationRuleDto[]): void {
    this.setModel({
      ...this.model,
      rules: escalationRules
    });
    setTimeout(() => this.onModelChange(this.model));
  }

  public onLinksChange(links: LinkDto[]): void {
    this.setModel({...this.model, links});
    setTimeout(() => this.onModelChange(this.model));
  }

  public onAttachmentsChange(attachments: AttachmentForTechDto[]): void {
    this.setModel({...this.model, attachments});
    setTimeout(() => this.onModelChange(this.model));
  }

  public onArticlesChange({knowledgeArticles, inheritsKnowledge}): void {
    this.setModel({...this.model, knowledgeArticles, inheritsKnowledge});
    setTimeout(() => this.onModelChange(this.model));
  }

  public onIssueTemplatesChange({issueTemplates, inheritsIssueTemplate}): void {
    this.setModel({...this.model, issueTemplates, inheritsIssueTemplate});
    setTimeout(() => this.onModelChange(this.model));
  }

  public onModelChange(model: ResourceDto): void {
    this.modelValidSubject.next({
      ...this.modelValidSubject.getValue(),
      escalationRules: this.escalationRulesComponent ? this.escalationRulesComponent.isModelValid() : true,
      attachments: this.attachmentsComponent ? this.attachmentsComponent.isModelValid() : true,
      quickAccess: this.quickAccessComponent ? this.quickAccessComponent.isModelValid() : true
    });

    super.onModelChange(model);
  }

  public onEditorSuggestionsChanged(suggestions: EditorSuggestionsChangedEvent): void {
    this.setModel({
      ...this.model,
      primarySuggestions: suggestions?.primary,
      alternativeSuggestions: suggestions?.alternative,
      inheritsSuggestions: suggestions?.isInheritSuggestions
    });
    setTimeout(() => this.onModelChange(this.model));
  }

  public onQuickAccessChange(options: QuickAccessOptionsPayload): void {
    this.setModel({
      ...this.model,
      inheritsQuickAccessConfiguration: options.isInherited,
      quickAccessConfiguration: {
        quickAccessConfigurationId: options.quickAccessConfigurationId,
        isActivated: options.isActivated,
        isShowingProperties: options.isShowingProperties,
        knowledgeConfiguration: {
          quickAccessKnowledgeConfigurationId: options.quickAccessKnowledgeConfigurationId,
          visibility: options.knowledgeVisibility,
          knowledgeArticles: options.knowledgeArticles
        },
        issueTemplateConfiguration: {
          quickAccessIssueTemplateConfigurationId: options.quickAccessIssueTemplateConfigurationId,
          visibility: options.issueTemplateVisibility,
          historyVisibility: options.issueTemplateHistoryVisibility,
          issueTemplates: options.issueTemplates
        },
        dashboardConfiguration: {
          quickAccessDashboardConfigurationId: options.quickAccessDashboardConfigurationId,
          visibility: options.dashboardVisibility
        }
      }
    });
    setTimeout(() => this.onModelChange(this.model));
  }

  public isModelValid(): boolean {
    return super.isModelValid();
  }

  public isOwnModelValid(): boolean {
    return super.isOwnModelValid();
  }

  public isOwnModelPristine(): boolean {
    return this.form.pristine;
  }

  public onAddManufacturer(name: string): void {
    this.manufacturerService.createOne({name, tenantId: this.currentTenantId}).pipe(take(1)).subscribe((response) => {
      this.refreshManufacturerSubject.next(true);
      setTimeout(() => this.model.manufacturer = response);
    });
  }

  public onAddModel(name: string, manufacturerId: number): void {
    this.manufacturerModelService.createOne({
      name,
      tenantId: this.currentTenantId
    }, [manufacturerId]).subscribe((response) => {
      this.refreshManufacturerModelSubject.next(true);
      setTimeout(() => this.model.model = response);
    });
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      ...(this.creating ? [this.inventoryNumberCountField] : []),
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          className: combineLatest([this.selectedStereotype$, this.resourceTypeSubject.asObservable()]).pipe(
            map(([stereotype, resourcetype]) => this.creating && stereotype?.inventoryNumberPattern ? (resourcetype !== ResourceType.Device ? 'col-md-12' : 'col-md-8') : 'col-md-12')
          )
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'type',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: ResourceType.Device,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.resource-type',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: resourceTypeEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: this.creating ? resourceTypeSelectableCreateEnumOptions : resourceTypeSelectableEditEnumOptions,
            translate: true
          },
          change: (field) => this.resourceTypeSubject.next(field.formControl.value),
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly || !this.creating && (this.model?.type === ResourceType.Virtual || this.model?.type === ResourceType.VirtualGroup)
        },
        hooks: {
          onInit: (field) => {
            field.templateOptions.disabled = this.model?.type === ResourceType.Group;
            this.subscribe(field.formControl.valueChanges.pipe(startWith(field.formControl.value)), (value) => this.resourceTypeSubject.next(value));
          }
        }
      },
      {
        key: 'parent',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.inherits-from'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (resource: ResourceDto) => resource?.resourceId ? ['/resources', resource.resourceId] : null,
            module: 'inventory'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.resourceService,
          entityId: this.model?.resourceId ?? undefined,
          idKey: 'resourceId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          mapSearchFilter: CorePortalFeatureResourceService.mapSearchFilter,
          search: CorePortalFeatureResourceService.searchCompare,
          link: (resource: ResourceDto) => resource?.resourceId ? ['/resources', resource.resourceId] : null,
          module: 'inventory',
          selectLabelTitleTemplate: this.resourceSelectLabelTitleTemplate,
          selectOptionTitleTemplate: this.resourceSelectOptionTitleTemplate,
          selectOptionTemplate: this.resourceSelectOptionTemplate,
          editable: true,
          onEdit: (event: MouseEvent, base: () => void) => {
            this.confirmationService.confirm({
              key: 'edit-parent',
              target: event.currentTarget,
              message: this.translate.instant('resources.descriptions.change-parent'),
              icon: NexnoxWebFaIconString.transformIcon(faExclamationTriangle),
              acceptLabel: this.translate.instant('core-portal.core.general.yes'),
              acceptButtonStyleClass: 'p-button-warning',
              rejectLabel: this.translate.instant('core-portal.core.general.cancel'),
              rejectButtonStyleClass: 'p-button-text p-button-secondary',
              accept: base
            });
          }
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.editable': () => !this.creating,
          'templateOptions.description': model => CorePortalFeatureResourcePathComponent.preparePath(model?.parent)
        },
        hooks: {
          onInit: field => {
            this.subscribe(field.formControl.valueChanges.pipe(
              map(parent => parent?.resourceId ?? 0),
              distinctUntilChanged()
            ), value => this.selectedParentIdSubject.next(value));

            this.subscribe(field.formControl.valueChanges.pipe(
              distinctUntilChanged((a, b) => isEqual(a, b)),
              skip(1)
            ), () => {
              this.onEscalationRulesChange([]);
              this.escalationRulesComponent?.reset();
            });
          }
        }
      },
      {
        key: 'location',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.location'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (location: LocationDto) => location?.locationId ? ['/masterdata', 'locations', location.locationId] : null,
            module: 'management'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.locationService,
          idKey: 'locationId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          mapSearchFilter: CorePortalFeatureMasterDataLocationService.mapSearchFilter,
          search: CorePortalFeatureMasterDataLocationService.searchCompare,
          link: (location: LocationDto) => location?.locationId ? ['/masterdata', 'locations', location.locationId] : null,
          module: 'management',
          selectLabelTitleTemplate: this.locationSelectLabelTitleTemplate,
          selectOptionTitleTemplate: this.locationSelectOptionTitleTemplate,
          defaultFilters$: noClosedLocationsFilter$
        },
        hideExpression: (model) => model.type === ResourceType.VirtualGroup || model.type === ResourceType.Group,
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'locationGroup',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.location-group-virtual',
            descriptionTemplate: this.locationGroupDescriptionTemplate
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (locationGroup: LocationGroupDto) => locationGroup?.locationGroupId ? ['/masterdata', 'locationgroups', locationGroup.locationGroupId] : null,
            module: 'management'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.locationGroupService,
          idKey: 'locationGroupId',
          displayKey: 'name',
          wholeObject: true,
          multiple: false,
          showAll: true
        },
        hideExpression: (model) => +model.type !== ResourceType.VirtualGroup,
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'installationDate',
        type: 'core-portal-datepicker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.installation-date'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'LL'
          } as CorePortalFormlyReadonlyTyping
        },
        hideExpression: () => !this.isDevice(),
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'serialNumber',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.serial-number',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
        },
        hideExpression: () => !this.isDevice(),
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'externalId',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        type: 'core-portal-divider',
        className: 'col-md-12'
      },
      {
        key: 'manufacturer',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-5',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.manufacturer'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (e: ResourceManufacturerDto) => e?.resourceManufacturerId ? ['/manufacturers', e.resourceManufacturerId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.manufacturerService,
          idKey: 'resourceManufacturerId',
          displayKey: 'name',
          multiple: false,
          wholeObject: true,
          skipGetOne: true,
          refresh$: this.refreshManufacturerSubject.asObservable(),
          addTagFn: (name) => this.onAddManufacturer(name),
          link: (e: ResourceManufacturerDto) => e?.resourceManufacturerId ? ['/manufacturers', e.resourceManufacturerId] : null,
          module: 'settings',
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly || this.model.inheritsManufacturer === true,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: (field) => this.subscribe(
            field.formControl.valueChanges.pipe(
              distinctUntilChanged((prev, curr) => isEqual(prev, curr))),
            () => this.refreshManufacturerModelSubject.next(true)
          )
        }
      },
      {
        key: 'inheritsManufacturer',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated'],
        defaultValue: false,
        className: 'col-md-1',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.is-inherited'
          }
        },
        expressionProperties: {
          'templateOptions.disabled': () => !this.model?.parent?.resourceId || this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: (field) => {
            this.subscribe(field.formControl.valueChanges, (value) => {
              if (value === false) {
                this.form.get('inheritsModel').setValue(false);
              }
            });
          }
        }
      },
      {
        key: 'model',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-5',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.model'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (e: ResourceModelDto) => e?.resourceModelId ? ['/manufacturers', this.model?.manufacturer?.resourceManufacturerId, 'model', e.resourceModelId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.manufacturerModelService,
          idKey: 'resourceModelId',
          displayKey: 'name',
          overrideGetPage: (pageNumber: number, pageSize: number, filters: FilterDto[]) => {
            const manufacturerId = this.model?.manufacturer?.resourceManufacturerId;
            return this.manufacturerModelService.getPage(
              undefined,
              pageNumber,
              filters,
              undefined,
              undefined,
              undefined,
              pageSize,
              [manufacturerId]
            ).pipe(take(1)).toPromise();
          },
          refresh$: this.refreshManufacturerModelSubject.asObservable(),
          wholeObject: true,
          skipGetOne: false,
          addTagFn: (name) => this.onAddModel(name, this.model?.manufacturer?.resourceManufacturerId),
          link: (e: ResourceModelDto) => e?.resourceModelId ? ['/manufacturers', this.model?.manufacturer?.resourceManufacturerId, 'model', e.resourceModelId] : null,
          module: 'settings',
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly || !this.model?.manufacturer || this.model.inheritsModel === true,
          'templateOptions.readonly': () => this.readonly
        },
      },
      {
        key: 'inheritsModel',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated'],
        className: 'col-md-1',
        defaultValue: false,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.is-inherited'
          }
        },
        expressionProperties: {
          'templateOptions.disabled': () => !this.model?.parent?.resourceId || this.readonly || this.model.inheritsManufacturer === false,
          'templateOptions.readonly': () => this.readonly
        }
      },
      ...this.getStereotypeFields()
    ];
  }

  /* istanbul ignore next */
  protected getTitleFields(base: FormlyFieldConfig): FormlyFieldConfig[] {
    return [
      this.inventoryNumberCountField,
      {
        ...base,
        expressionProperties: {
          ...base.expressionProperties,
          className: this.selectedStereotype$.pipe(
            map(stereotype => !stereotype?.inventoryNumberPattern || this.resourceTypeSubject.getValue() !== ResourceType.Device ? 'col-md-12 title-input' : 'col-md-8 title-input')
          )
        }
      }
    ];
  }

  protected setModel(model: ResourceDto): void {
    super.setModel(model);

    this.resourceSubject.next(model ?? null);
    this.primaryEditorSuggestionsSubject.next(model?.primarySuggestions ?? []);
    this.alternativeEditorSuggestionsSubject.next(model?.alternativeSuggestions ?? []);
    this.escalationRulesSubject.next(model?.rules ?? []);
    this.linksSubject.next(model?.links ?? []);
    this.attachmentsSubject.next(model?.attachments ?? []);
    this.articlesSubject.next(model.knowledgeArticles ?? []);
    this.issueTemplatesSubject.next(model.issueTemplates ?? []);
  }

  /* istanbul ignore next */
  protected setReadonly(readonly: boolean): void {
    super.setReadonly(readonly);

    this.escalationRulesComponent?.reset();
    this.linksComponent?.onReset();
    this.attachmentsComponent?.onReset();
  }

  /* istanbul ignore next */
  private getInventoryNumberCountField(): FormlyFieldConfig {
    return {
      key: 'inventoryNumberCount',
      type: 'core-portal-input-group-input',
      wrappers: ['core-portal-translated'],
      className: null,
      templateOptions: {
        corePortalTranslated: {
          label: 'core-shared.shared.fields.inventory-number',
          validationMessages: {
            required: 'core-portal.core.validation.required',
          }
        },
        corePortalInputGroupInput: {
          prepend: this.selectedStereotype$.pipe(
            map((stereotype: StereotypeListDto) => {
              if (!stereotype?.inventoryNumberPattern?.prefix) {
                return [];
              }

              return [stereotype.inventoryNumberPattern.prefix];
            })
          ),
          append: this.selectedStereotype$.pipe(
            map((stereotype: StereotypeListDto) => {
              if (!stereotype?.inventoryNumberPattern?.suffix) {
                return [];
              }

              return [stereotype.inventoryNumberPattern.suffix];
            })
          )
        },
        type: 'number'
      },
      expressionProperties: {
        'templateOptions.required': () => !this.readonly,
        'templateOptions.disabled': () => this.readonly,

        className: () => this.creating ? 'col-md-4' : 'col-md-4 title-input',
        'templateOptions.corePortalTranslated.hideLabel': () => !this.creating ? true : undefined,
        'templateOptions.corePortalTranslated.formGroupClassName': () => !this.creating ? 'mb-0' : undefined,

        hide: combineLatest([this.selectedStereotype$, this.resourceTypeSubject.asObservable()]).pipe(
          map(([stereotype, resourcetype]) => !stereotype?.inventoryNumberPattern || (resourcetype ?? this.model.type) !== ResourceType.Device)
        )
      },
    };
  }

  private isDevice(): boolean {
    return this.model?.type === ResourceType.Device;
  }
}
